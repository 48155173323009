import React from 'react'
import Navbar from '../NavBar/Navbar'
import Footer from '../Footer/Footer'

export default function UpdateMenu() {
    return (
        <div>
            <Navbar/>
            {/* <section className="xs-banner-inner-section parallax-window" style={{backgroundImage:"url('assets/images/blog/blog_2.jpg')"}}>
	<div className="xs-black-overlay"></div>
	<div className="container">
		<div className="color-white xs-inner-banner-content">
			<h2>Blogs</h2>
			
		</div>
	</div>
</section> */}



<main className="xs-main">
	
	<section className="xs-content-section-padding">
	<div className="container">

        
    <div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/partnership1.jpeg" alt="" style={{height:"230px", width:"500px"}} />
						<div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div>
					</div>
					<div className="entry-header">
						<div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
								
								</a>
							</span>
						</div>
						
						<h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4>
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


            <div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/partnership2.jpeg" alt="" style={{height:"230px", width:"500px"}} />
						<div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="#">By Ruralnomics Team</a>
							</span>
						</div>
					</div>
					<div className="entry-header">
						<div className="entry-meta">
							<span className="date">
								<a href="#"  rel="bookmark" className="entry-date">
							
								</a>
							</span>
						</div>
						
						<h4 className="entry-title">
							<a href="#">Ruralnomics, Bikozee & Bahniban Farms Partnership.
</a>
						</h4>
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


            <div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/partnership3.jpeg" alt="" style={{height:"230px", width:"500px"}} />
						<div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="#">By Ruralnomics Team</a>
							</span>
						</div>
					</div>
					<div className="entry-header">
						<div className="entry-meta">
							<span className="date">
								<a href="#"  rel="bookmark" className="entry-date">
									
								</a>
							</span>
						</div>
						
						<h4 className="entry-title">
							<a href="#">Ruralnomics & Lavender Greens Strategic Tie Up.
</a>
						</h4>
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>

		
		</div>
        </section>
	

            </main>
            
            <Footer/>
        </div>
    )
}
