import React from 'react'
import Navbar from '../NavBar/Navbar'
import Footer from '../Footer/Footer'

export default function Blogpost() {
    return (
        <div>
            <Navbar/>
            <section className="xs-banner-inner-section parallax-window" style={{backgroundImage:"url('assets/image/blogs.jpg')"}}>
                <div className="xs-black-overlay"></div>
                <div className="container">
                    <div className="color-white xs-inner-banner-content">
                        <h2 className="text-white">Blog</h2>
                        {/* <p>Give a helping hand for poor people</p> */}
                        
                    </div>
                </div>
            </section>


            <main className="xs-main">
	
	<div className="xs-content-section-padding xs-blog-single">


	<div className="container">
		<div className="row">
			<div className="col-md-12 col-lg-8">
				 
				<article className="post format-standard hentry xs-blog-post-details">
					<div className="post-media post-image">
						<img src="assets/image/festival.jpg" className="img-responsive" alt="" />
					</div>

					<div className="post-body xs-border xs-padding-40">
						<div className="entry-header">
							<div className="post-meta row">
								<div className="col-md-2 xs-padding-0">
				 					<span className="post-meta-date"><b>29</b> June</span>
				 				</div>
								
							</div>
			 				<h2 className="entry-title xs-post-entry-title">
				 				<a href="#"> Most Famous Festivals in North East India.</a>
				 			</h2>
						</div>
						
						<div className="entry-content">
							<p style={{textAlign:"justify", textJustify:"inter-word"}}>Festivals in North East India flaunt its rich culture. Most of the festivals here are either related to agriculture, Buddhism or beginning of a New Year. There are many festivals that extend over a number of days and include folk/tribal dance and music. These festivals also present a wonderful opportunity for the people belonging to different tribes to bond. The sumptuouSaga Dawa is one of the major Buddhist festivals in Sikkim and is celebrated in the Tibetan lunar month. The full moon day that falls in the middle of the lunar month is known as Saga Dawa and it is considered as an auspicious day by the Buddhists. Saga Dawa is called the ‘Month of Merits’ and is celebrated between May and June. The festival commemorates the birth, enlightment and death of the Buddha. On the occasion of Saga Dawa in Sikkim, people gather at the monasteries and offer water, incense sticks and dhog. Also, many participate in the circumambulation of the Gompas, where they chant mantras, carry the religious text and turn the prayer wheels.s delicacies, interesting costumes, soulful music, warm hospitality and the lively ambiance give one enough reasons to be a part of the festivity in the North East.</p>

							

							{/* <blockquote>
								Welcome to MONTH #4 of my Song of the Month project! In case you missed months one three, here's a little background on what I'm trying to do: I've released 6 albums list here available.
							</blockquote> */}

							
							
							{/* <h3 className="entry-sub-title">Farm-to-table selfies labore leggings:</h3> */}

							{/* <ul className="xs-unorder-list play">
								<li>Plaid fashion axe semiotics skateboard</li>
								<li>Mixtape fap Intelligentsia small batch placeat labore</li>
								<li>Gleams steal into the inner sanctuary grow</li>
								<li>Like these sweet mornings of spring which</li>
							</ul> */}

                            <h3 className="entry-sub-title">Losar, Arunachal Pradesh</h3>
                            <p style={{textAlign:"justify", textJustify:"inter-word"}}>Losar is a major festival in Arunachal Pradesh as it marks the Tibetan New Year. Tribes like Monpa, Sherdukpens, Memba, Khamba and Nah following the Mahayana sect of Buddhism celebrate this festival with all pomp. This three-day festival begins on February 11 each year. On the first day of the festival, the priests make offering to the highest priest called the Dharmapala or Palden Lhamo, whereas the masses visit friends and family and wish them Tashi Delek  (best wishes). On the second day of the festival, which is also called Gyalpo Losar, national leaders and kings are honoured. On the third and final day of the festival known as Choe-Kyong Losar, people make offerings  to Dharmapala and tie prayer flags on rooftops and across the locality.</p>
                            <h3 className="entry-sub-title">Saga Dawa, Sikkim</h3>
                            <p style={{textAlign:"justify", textJustify:"inter-word"}}>Saga Dawa is one of the major Buddhist festivals in Sikkim and is celebrated in the Tibetan lunar month. TDree Festival, Arunachal Pradeshhe full moon day that falls in the middle of the lunar month is known as Saga Dawa and it is considered as an auspicious day by the Buddhists. Saga Dawa is called the ‘Month of Merits’ and is celebrated between May and June. The festival commemorates the birth, enlightment and death of the Buddha. On the occasion of Saga Dawa in Sikkim, people gather at the monasteries and offer water, incense sticks and dhog. Also, many participate in the circumambulation of the Gompas, where they chant mantras, carry the religious text and turn the prayer wheels.</p>
						    <h3 className="entry-sub-title">Ambubachi Mela, Assam</h3>
                            <p style={{textAlign:"justify", textJustify:"inter-word"}}>Ambubachi Mela is held in Guwahati, Assam. The festival is celebrated during the month of June in the Kamakhya Temple. Thousands of devotees from all over India and abroad throng the temple; thus this mela can rightly be called the ‘Mahakumbh of the East’. It is believed that during this time of each year Goddess Kamakhya goes through her annual menstrual cycle. The temple remains closed for three days and is considered unclean, however the devotees throng the temple’s premises to seek Goddess’ blessing even though the doors are closed. It is also during this time of the year when the Tantrik Cult is seen performing various unusual rites. After three days the temple re-opens and the devotees are allowed to offer prayer to the deity. </p>
                            <h3 className="entry-sub-title">Dree Festival, Arunachal Pradesh</h3>   
                            <p style={{textAlign:"justify", textJustify:"inter-word"}}>Dree is the festival of the Apatani Tribe, it has gained in popularity amongst other tribes in Arunachal Pradesh as well. The festival takes place on July 5 each year; however celebrations associated with the festival begin from July 4 itself. Dree is the biggest festival of the Ziro Valley and is celebrated to ensure a good harvest. During the festival, people offer prayers to four Gods namely, Tamu, Harniang, Metii, and Danyi. Traditional dance is performed and as a symbol of good harvest cucumber is distributed to all. Women brew wine and people also savour various delicacies and rice/millet beer. </p>
                            <h3 className="entry-sub-title">Moatsu Mong, Nagaland</h3>
                            <p style={{textAlign:"justify", textJustify:"inter-word"}}>Held in Mokokchung district, Moatsu Mong festival begins with the cleaning of wells and the brewing of beer. During the festival people savour the taste of local delicacies and enjoy cultural programs. Where men perform traditional warrior dance, women sing songs that praise Aovillages. Moatsu Mong is a very colourful festival and is also the symbol of the rich Naga culture. This festival of the Ao tribe in Nagaland marks the completion of the sowing season. It is a three-day festival that is celebrated from May 1 to 3 each year.</p>
                            <h3 className="entry-sub-title">Anthurium, Mizoram</h3>
                            <p style={{textAlign:"justify", textJustify:"inter-word"}}>Named after a famous flower, Anthurium is a major festival in Mizoram. Anthurium is celebrated with an objective of promoting tourism and it is held in the month of September each year. A small village called Reiek (about 70kms from capital city Aizawl) is the venue for this fest. Folk dance, music and cuisine are the highlight of this festival.</p>
                            <h3 className="entry-sub-title">Lui-ngai-ni, Manipur</h3>
                            <p style={{textAlign:"justify", textJustify:"inter-word"}}>Lui-Ngai-Ni is celebrated by almost all the tribes of the Nagas; thus it is celebrated not only in Nagaland but in Manipur (Naga inhabited areas) as well. The festival takes place on February 15 each year and marks the seedsowing season. Known for strengthening the bond between the Naga tribes, Lui-Ngai-Ni is celebrated with much pomp. During the festival, traditional dances are performed; different delicacies are prepared rituals of lighting fire and blessing the seeds that will be sown are also performed. Lui-Ngai-Ni festival gives out the message of peace and harmony.</p>
                            <h3 className="entry-sub-title">Bihu Festival, Assam</h3>
                            <p style={{textAlign:"justify", textJustify:"inter-word"}}>Celebrated to welcome the Assamese New Year, Bohag Bihu is one of the most important festival of Assam. The Assamese celebrate Bihu thrice in a year namely, Bohag or Rongali Bihu, Kaati or Kongali Bihu and Maagh or Bhogali Bihu. The Bohag Bihu calls for huge celebration beginning from April 14 each year. Although the festival is observed for 7 days, the celebration extends for about a month. The first day of Bohag Bihu is called Goru Bihu, in which cow and bulls are bathed and fed. The main festival is observed on the second day; people greet each other and present their relatives with Gamosa (hand-woven cotton towels). On this day, young Assamese men and women form groups and perform Bihu Dance. Assamese delicacies are prepared out of which Pitha (dish made of rice powder, wheat flour, coconut, sesame and jaggery) is the specialty. Stages are set in different parts of the state, where Bihu Dance is performed on a regular basis for a month.</p>
                            <h3 className="entry-sub-title">Hornbill Festival, Kohima Nagaland</h3>
                            <p style={{textAlign:"justify", textJustify:"inter-word"}}>Named after a bird, Hornbill Festival is a major festival in Nagaland. Like many festivals in the state, Hornbill festival is also related to agriculture. It is a 7 to 10 days festival that begins with immense zeal from December 1 every year. Held in Kisama Heritage Village, this festival is attended by all the major tribes in Nagaland. Hornbill is a good time to witness the cultural vividness; it also gives the opportunity to bond with different communities. The warrior log drums, colorful headgear, and soulful war cry, exquisite costumes are the highlights of the festival. The Kohima Night Bazaar and contests like pork eating and King Chilly add tingly flavour to the festive mood.</p>
                            <h3 className="entry-sub-title">Losoong, Sikkim</h3>
                            <p style={{textAlign:"justify", textJustify:"inter-word"}}>Losoong is the Sikkimese New Year and is celebrated each year in the month of December. It is also that time of the year when the farmers celebrate their harvest. Although Losoong Festival is a traditional celebration of the Bhutia tribe, the Lepchas also celebrate it with equal vigour and zeal. Dances are performed (Cham Dance and Black Hat Dance) at the monasteries and the people drink the locally brewed wine called Chaang. Archery competition is also organized to further enhance the festival spirit.</p>
                            <h3 className="entry-sub-title">Nongkrem Dance Festival, Meghalaya</h3>
                            <p style={{textAlign:"justify", textJustify:"inter-word"}}>Nongkrem Dance Festival is a way of celebrating the harvest. The festival is celebrated in the month of November each year by the Khasi Tribe, who sacrifices goats as a thanksgiving offering to Goddess Ka Blei Synshar. It is a five day festival in which young men and women dressed in interesting traditional costume perform tribal dance. Nongkrem Dance Festival is held in Smit, which is about 15kms from the famed city of Shillong.</p>
                            <h3 className="entry-sub-title">Kharchi Puja, Tripura</h3>
                            <p style={{textAlign:"justify", textJustify:"inter-word"}}>Kharchi is a popular festival in Tripura. The Puja (worship) was once performed only by the royal households; however with changing times, Kharchi became common to all the houses in Tripura. It is a 10-day long festival that is marked by animal sacrifice and worshipping 14 gods as instructed by Lord Shiva. The festival takes place in the month of July each year in Old Agartala or Puran Haveli, where there is a temple that houses 14 deities. Thousands of people throng Tripura during the festival and enjoy cultural programmes and fairs.</p>
                            <h3 className="entry-sub-title">Chapchar Kut, Mizoram</h3>
                            <p style={{textAlign:"justify", textJustify:"inter-word"}}>Chapar Kut is an agriculture festival in Mizoram. Celebrated in the month of March every year, Chapar Kut marks the time for planting in the state. The highlight of this festival is the bamboo dance called Cheraw. Apart from this special dance, different style of dances (Khuallam, Chheihlam, Chai and Sarlamkai) are also performed during the festival, traditional songs are sung amidst the cymbal clashes, beats of the drums and sound of the gongs. Handicraft, art and flower show along with food festival and music competitions are organized during the festival. Chapchar Kut is celebrated in Mizoram, Lunglei and Saiha.</p>
                            <h3 className="entry-sub-title">Wangala Festival, Meghalaya</h3>
                            <p style={{textAlign:"justify", textJustify:"inter-word"}}>Wangala is a winter festival in Meghalaya that is mainly celebrated by the Garo tribe. It is held in the second week of November each year in Asanang village near Tura in the Garo Hills and is considered to be a post-harvest celebration. The festival is celebrated in the honor of Misi Saljong, who is considered to be a generous giver. The village chief called Nokma performs rituals a day before the festival begins during which freshly brewed rich beer, cooked rice and vegetables are offered to Misi Saljong. Wangala Festival is also called ‘100 Drum Wangala Festival’; the main attraction of this celebration is the music. Drums, orchestra and flutes offer a wonderful and mesmerizing ambiance that amplifies the festive mood. The feathered head gear is yet another attraction that is worn by everyone celebrating the festival.</p>
                            <h3 className="entry-sub-title">Kang Chingba, Manipur</h3>
                            <p style={{textAlign:"justify", textJustify:"inter-word"}}>Kang Chingba is the biggest Hindu festival in Manipur. The Kang Chingba festival is celebrated in the month of July each year and is similar to Puri Rath Yatra. It is an 8-day festival, which celebrates the journey of Lord Jagannath, his brother Balabhadra, and sister Subhadra. Thousands of devotees throng Imphal during the festival and help pull the massive chariots that carry the deities. Large feasts are organized and people dance all through the night. The Yatra begins from Sri Sri Govindajee Temple in Imphal and the car in which Lord Jagannath is carried is called ‘Kang’.</p>
                        
                        </div>

						{/* <div className="post-footer">
							<div className="xs-post-footer xs-padding-40 xs-border">
								<div className="post-tags">
									<h5 className="xs-post-sub-heading">Releted Tags</h5>
									<div className="xs-blog-post-tag">
										<a href="#">Hopes</a>
										<a href="#">Medical</a>
									</div>
								</div>

								<div className="share-items">
									<h5 className="xs-post-sub-heading">Social Share</h5>
									<ul className="xs-social-list square">
										<li><a href="#" className="color-facebook"><i className="fa fa-facebook"></i></a></li>
										<li><a href="#" className="color-twitter"><i className="fa fa-twitter"></i></a></li>
										<li><a href="#" className="color-dribbble"><i className="fa fa-dribbble"></i></a></li>
										<li><a href="#" className="color-pinterest"><i className="fa fa-pinterest"></i></a></li>
										<li><a href="#" className="color-instagram"><i className="fa fa-instagram"></i></a></li>
									</ul>
								</div>
								<div className="clearfix"></div>
							</div>
							<div className="xs-author-block xs-padding-40 xs-border">
								<div className="post-author">
									<div className="xs-round-avatar float-left">
										<img src="assets/images/avatar/avatar_7.jpg" alt="" className="img-responsive" />
									</div>

									<div className="xs-post-author-details float-right">
										<a href="#">Jhon William</a>
										<em>
											<i className="fa fa-map-marker color-green"></i>New York, USA
										</em>
										<span className="xs-separetor"></span>
										<ul className="xs-social-list simple">
											<li><a href="#" className="color-facebook"><i className="fa fa-facebook"></i></a></li>
											<li><a href="#" className="color-twitter"><i className="fa fa-twitter"></i></a></li>
											<li><a href="#" className="color-dribbble"><i className="fa fa-dribbble"></i></a></li>
											<li><a href="#" className="color-pinterest"><i className="fa fa-pinterest"></i></a></li>
											<li><a href="#" className="color-instagram"><i className="fa fa-instagram"></i></a></li>
										</ul>
									</div>
									<div className="clearfix"></div>
								</div>

								<div className="post-content">
									<p className="xs-mb-0">I love advice columns, always have. Growing up, I read “Dear Abby” and “Ask Ann Landers.” I enjoyed the voyeurism — glimpses into the lives with hapy family.</p>
								</div>
								<div className="clearfix"></div>
							</div>
							<nav className="navigation post-navigation" role="navigation">
								<div className="nav-links float-left w-50">
									<a href="#" rel="prev" className="prev">
										<h5>Crowdfunding resource</h5>
										<span className="meta-nav"><i className="fa fa-angle-left"></i>Prev Post</span>
									</a>
								</div>
								<div className="nav-links float-right w-50 text-right">
									<a href="#" rel="next" className="next">
										<h5>Funding means life</h5>
										<span className="meta-nav">next Post<i className="fa fa-angle-right"></i></span>
									</a>
								</div>
								<div className="clearfix"></div>
							</nav>
						</div> */}
					</div>
				</article>



            
				
				
				
				{/* <div className="xs-blog-post-comment xs-padding-40 xs-border">
					<h4 className="comments-title"> 4 Comments</h4>
					
					<ul className="comment-list">
						<li className="comment">
							<div className="comment-body">
								<div className="comment-meta">
									<div className="comment-author">
										<img alt="avatar" src="assets/images/avatar/avatar_9.jpg" className="avatar" />
										<b>Jhony WIlliamson</b>
									</div>
									<div className="comment-metadata">
									
									</div>
								</div>

								<div className="comment-content">
									<p>On the evening of November 10th, the audience at New York’s Metry opolitiona era was treated to the briefest of delights.</p>
								</div>
								<div className="reply">
									<a href="#"> 
										<i className="fa fa-mail-forward"></i>
										Reply
									</a>
								</div>
							</div>
						</li>
						<li className="comment">
							<div className="comment-body">
								<div className="comment-meta">
									<div className="comment-author">
										<img alt="avatar" src="assets/images/avatar/avatar_7.jpg" className="avatar" />
										<b>William Smith</b>
									</div>
									<div className="comment-metadata">
										
									</div>
								</div>

								<div className="comment-content">
									<p>On the evening of November 10th, the audience at New York’s Metry opolitiona era was treated to the briefest of delights.</p>
								</div>
								<div className="reply">
									<a href="#"> 
										<i className="fa fa-mail-forward"></i>
										Reply
									</a>
								</div>
							</div>
							<ul className="children">
								<li className="comment">
									<div className="comment-body">
										<div className="comment-meta">
											<div className="comment-author">
												<img alt="" src="assets/images/avatar/avatar_8.jpg" className="avatar" />
												<b>Julian Jenny</b> 
											</div>

											<div className="comment-metadata">
												
											</div>
										</div>

										<div className="comment-content">
											<p>On the evening of November 10th, the audience at New York’s Metry opolitans Opera was treated to the briefest of delights.</p>
										</div>

										<div className="reply">
											<a href="#"> 
												<i className="fa fa-mail-forward"></i>
												Reply
											</a>
										</div>
									</div>
								</li>
								<li>
									<ul>
										<li className="comment">
											<div className="comment-body">
												<div className="comment-meta">
													<div className="comment-author">
														<img alt="" src="assets/images/avatar/avatar_10.jpg" className="avatar" />
														<b>Julian Jenny</b> 
													</div>

													<div className="comment-metadata">
														
													</div>
												</div>

												<div className="comment-content">
													<p>On the evening of November 10th, the audience at New York’s Metry opolitans Opera was treated to the briefest of delights.</p>
												</div>

												<div className="reply">
													<a href="#"> 
														<i className="fa fa-mail-forward"></i>
														Reply
													</a>
												</div>
											</div>
										</li>
									</ul>
								</li>
							</ul>
						</li>
					</ul>
					
					
					
					<div className="comment-respond">
						<h3 className="comment-reply-title">Leave a comments</h3>
									
						<form action="http://demo.xpeedstudio.com/html/charitypress/POST" method="post" className="comment-form">
							<input placeholder="Enter Name *" name="author" type="text">
							
							<div className="row">
								<div className="col-lg-6">
									<input placeholder="Enter Email *" name="email" type="email">
								</div>
								<div className="col-lg-6">
									<input placeholder="Enter Website" name="url" type="url">
								</div>
							</div>

							<textarea placeholder="Enter Comments *" name="comment" cols="45" rows="8"></textarea>
							
							<div className="text-right">
								<button type="submit" className="btn btn-primary" name="submit">Post Comment</button>
							</div>
						</form>
					</div>
				
				</div> */}
				
			</div>
			<div className="col-md-12 col-lg-4">
			
				<div className="sidebar sidebar-right">
	
	<div className="widget widget_search">	
		{/* <form className="xs-serachForm" method="get" action="http://demo.xpeedstudio.com/html/charitypress/GET">
			<input type="search" name="search" placeholder="Type keywords...">
			<input type="submit" value="">
		</form> */}
	</div>
{/* 	
	<div className="widget recent-posts xs-sidebar-widget">
		<h3 className="widget-title">Trending Post</h3>
		<ul className="xs-recent-post-widget">
			<li>
				<div className="posts-thumb float-left"> 
					<a href="#">
						<img alt="img" className="img-responsive" src="assets/images/news_feeds_1.jpg" />
						<div className="xs-entry-date">
							<span className="entry-date d-block">21</span>
							<span className="entry-month d-block">dec</span>
						</div>
						<div className="xs-black-overlay bg-aqua"></div>
					</a>
				</div>
				<div className="post-info">
					<h4 className="entry-title">
						<a href="#">Child Care Centers</a>
					</h4>
					<div className="post-meta">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
					</div>
				</div>
				<div className="clearfix"></div>
			</li>
			<li>
				<div className="posts-thumb float-left"> 
					<a href="#">
						<img alt="img" className="img-responsive" src="assets/images/news_feeds_2.jpg" />
						<div className="xs-entry-date">
							<span className="entry-date d-block">23</span>
							<span className="entry-month d-block">sep</span>
						</div>
						<div className="xs-black-overlay bg-aqua"></div>
					</a>
				</div>
				<div className="post-info">
					<h4 className="entry-title">
						<a href="#">Disaster Relief</a>
					</h4>
					<div className="post-meta">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">35 Comments</a>
						</span>
					</div>
				</div>
				<div className="clearfix"></div>
			</li>
		</ul>
	</div> */}
	
	{/* <div className="widget widget_categories xs-sidebar-widget">
		<h3 className="widget-title">Categories</h3>
		<ul className="xs-side-bar-list">
			<li><a href="#"><span>Adoption</span><span>(33)</span></a></li>
			<li><a href="#"><span>Fundrise</span><span>(97)</span></a></li>
			<li><a href="#"><span>Bolunteer</span><span>(89)</span></a></li>
			<li><a href="#"><span>Water</span><span>(82)</span></a></li>
			<li><a href="#"><span>Foods</span><span>(76)</span></a></li>
			<li><a href="#"><span>Health</span><span>(93)</span></a></li>
		</ul>
	</div> */}
	
	<div className="widget widget_call_to_action">
		<a href="#" className="d-block">
			<img src="assets/images/side_add_baner.jpg" alt="" />
		</a>
	</div>
	
	{/* <div className="widget widget_instafeed xs-sidebar-widget">
		<h3 className="widget-title">Instagram Feeds</h3>
		<ul className="xs-demoFeed clearfix"></ul>
	</div> */}
	
	{/* <div className="widget widget_tags xs-sidebar-widget">
		<h3 className="widget-title">Popular Tags</h3>

		<div className="xs-blog-post-tag">
			<a href="#">Crowdfunding</a>
			<a href="#">Fundrise</a>
			<a href="#">70</a>
			<a href="#">Medicine</a>
			<a href="#">Food</a>
			<a href="#">7</a>
			<a href="#">Greeny</a>
			<a href="#">Fundrising</a>
			<a href="#">Hopes</a>
			<a href="#">Medical</a>
			<a href="#">Help</a>
		</div>
	</div> */}
	
	{/* <div className="widget widget_social_share xs-sidebar-widget">
		<h3 className="widget-title">Social Share</h3>

		<ul className="xs-social-list boxed">
			<li><a href="#" className="color-facebook"><i className="fa fa-facebook"></i>Facebook</a></li>
			<li><a href="#" className="color-twitter"><i className="fa fa-twitter"></i>twitter</a></li>
			<li><a href="#" className="color-dribbble"><i className="fa fa-dribbble"></i>Pinterest</a></li>
			<li><a href="#" className="color-pinterest"><i className="fa fa-pinterest"></i>Dribbble</a></li>
			<li><a href="#" className="color-instagram"><i className="fa fa-instagram"></i>Instagram</a></li>
			<li><a href="#" className="color-linkedin"><i className="fa fa-linkedin"></i>Linkedin</a></li>
		</ul>
	</div>
	
	<div className="widget widget_categories xs-sidebar-widget">
		<h3 className="widget-title">Archives</h3>
		<ul className="xs-side-bar-list">
			<li><a href="#"><span>2018 January</span><span>(6733)</span></a></li>
			<li><a href="#"><span>2017 January</span><span>(5897)</span></a></li>
			<li><a href="#"><span>2015 January</span><span>(4589)</span></a></li>
			<li><a href="#"><span>2014 January</span><span>(3082)</span></a></li>
			<li><a href="#"><span>2013 January</span><span>(2676)</span></a></li>
			<li><a href="#"><span>2012 January</span><span>(1995)</span></a></li>
		</ul>
	</div> */}

</div>			
			</div>
		</div>
	</div>
</div>

	{/* <section className="xs-section-padding bg-gray">
	<div className="container">
		<div className="xs-heading row xs-mb-60">
			<div className="col-md-9 col-xl-9">
				<h2 className="xs-title">From the Journal</h2>
				<span className="xs-separetor dashed"></span>
				<p>It allows you to gather monthly subscriptions from fans to help fund your creative projects. They also encourage their users to offer rewards to fans as a way to repay them for their support.</p>
			</div>
			<div className="col-xl-3 col-md-3 xs-btn-wraper">
				<a href="#" className="btn btn-primary">all Causes</a>
			</div>
		</div>
		<div className="row">
			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal">
					<div className="entry-thumbnail ">
						<img src="assets/images/blog/blog_1.jpg" alt="" />
						<div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/images/avatar/avatar_1.jpg" alt="" />
							</span>
							<span className="author-name">
								<a href="#">By Simona</a>
							</span>
						</div>
					</div>
					<div className="entry-header">
						<div className="entry-meta">
							<span className="date">
								<a href="#"  rel="bookmark" className="entry-date">
									27th August 2017
								</a>
							</span>
						</div>
						
						<h4 className="entry-title">
							<a href="#">Brilliant After All, A New Album by Rebecca: Help poor people</a>
						</h4>
					</div>
					<span className="xs-separetor"></span>
					<div className="post-meta">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div>
				</div>
			</div>
			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal">
					<div className="entry-thumbnail ">
						<img src="assets/images/blog/blog_2.jpg" alt="" />
						<div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/images/avatar/avatar_2.jpg" alt="" />
							</span>
							<span className="author-name">
								<a href="#">By Julian</a>
							</span>
						</div>
					</div>
					<div className="entry-header">
						<div className="entry-meta">
							<span className="date">
								<a href="#"  rel="bookmark" className="entry-date">
									02 May 2017
								</a>
							</span>
						</div>
						
						<h4 className="entry-title">
							<a href="#">South african pre primary school build for children</a>
						</h4>
					</div>
					<span className="xs-separetor"></span>
					<div className="post-meta">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div>
				</div>
			</div>
			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal">
					<div className="entry-thumbnail ">
						<img src="assets/images/blog/blog_3.jpg" alt="" />
						<div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/images/avatar/avatar_3.jpg" alt="" />
							</span>
							<span className="author-name">
								<a href="#">By David Willy</a>
							</span>
						</div>
					</div>
					<div className="entry-header">
						<div className="entry-meta">
							<span className="date">
								<a href="#"  rel="bookmark" className="entry-date">
									13 January 2017
								</a>
							</span>
						</div>
						
						<h4 className="entry-title">
							<a href="#">Provide pure water for syrian poor people</a>
						</h4>
					</div>
					<span className="xs-separetor"></span>
					<div className="post-meta">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>	 */}
</main>
            <Footer/>
        </div>
    )
}
