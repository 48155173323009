import React from 'react'
import Navbar from '../NavBar/Navbar'
import Footer from '../Footer/Footer'

export default function Blogpost1() {
    return (
        <div>
            <Navbar/>
            <section className="xs-banner-inner-section parallax-window" style={{backgroundImage:"url('assets/image/blogs.jpg')"}}>
                <div className="xs-black-overlay"></div>
                <div className="container">
                    <div className="color-white xs-inner-banner-content">
                        <h2 className="text-white">Blog</h2>
                        {/* <p>Give a helping hand for poor people</p> */}
                        
                    </div>
                </div>
            </section>


            <main className="xs-main">
	
	<div className="xs-content-section-padding xs-blog-single">


	<div className="container">
		<div className="row">
			<div className="col-md-12 col-lg-8">
				 



                <article className="post format-standard hentry xs-blog-post-details">
					<div className="post-media post-image">
						<img src="assets/image/agrotecheconomy.jpg" className="img-responsive" alt="" />
					</div>

					<div className="post-body xs-border xs-padding-40">
						<div className="entry-header">
							<div className="post-meta row">
								<div className="col-md-2 xs-padding-0">
				 					<span className="post-meta-date"><b>05</b> Sept</span>
				 				</div>
								
							</div>
			 				<h2 className="entry-title xs-post-entry-title">
				 				<a href="#"> What is Agro-Tech Economy?</a>
				 			</h2>
						</div>
						
						<div className="entry-content">
							<p style={{textAlign:"justify", textJustify:"inter-word"}}>Agricultural technology or agrotechnology is the use of technology in agriculture, horticulture, and aquaculture with the aim of improving yield, efficiency, and profitability. Agricultural technology can be products, services or applications derived from agriculture that improve various input/output processes.</p>

							

							{/* <blockquote>
								Welcome to MONTH #4 of my Song of the Month project! In case you missed months one three, here's a little background on what I'm trying to do: I've released 6 albums list here available.
							</blockquote> */}

							
							
							{/* <h3 className="entry-sub-title">Farm-to-table selfies labore leggings:</h3> */}

							{/* <ul className="xs-unorder-list play">
								<li>Plaid fashion axe semiotics skateboard</li>
								<li>Mixtape fap Intelligentsia small batch placeat labore</li>
								<li>Gleams steal into the inner sanctuary grow</li>
								<li>Like these sweet mornings of spring which</li>
							</ul> */}

                            <h3 className="entry-sub-title">History</h3>
                            <p style={{textAlign:"justify", textJustify:"inter-word"}}>The Industrial Revolution, also known as the First Industrial Revolution, was the transition to new manufacturing processes in Europe and the United States, in the period from about 1760 to sometime between 1820 and 1840. This transition included going from hand production methods to machines, new chemical manufacturing and iron production processes, the increasing use of steam power and water power, the development of machine tools and the rise of the mechanized factory system. The Industrial Revolution also led to an   unprecedented rise in the rate of population growth.</p>
                            <h3 className="entry-sub-title">Types of agricultural technology</h3>
                            <p>Technologies and applications in agricultural technology include:</p>
                            <ul className="xs-unorder-list play">
								<li>Low Height Drones</li>
								<li>Satellite photography and sensors</li>
								<li>IoT-based sensor networks</li>
								<li>Phase tracking</li>
                                <li>Weather forecasts</li>
                                <li>Automated irrigation</li>
                                <li>Light and heat control</li>
                                <li>Intelligent software analysis for pest and disease prediction, soil management and other involved analytical tasks</li>
                                <li>Biotech</li>
                            </ul>
                            <p style={{textAlign:"justify", textJustify:"inter-word"}}><strong>a) Mobile application for agriculture - </strong> Nigeria's digital agriculture platform which focused on connecting farm sponsors with real farmers in order to increase food production while promoting youth participation in agriculture. 
This agricultural technology startup is currently disrupting the agriculture ecosystem in the country by connecting small-scale farmers with investors using their platform App which is available on Google and Apple app stores. 
Farmers and sponsors all receive a percentage of the profits on harvest. The platform also makes provision for insurance cover for all existing farm projects, so that in the event of unforeseen circumstances, the sponsors’ capital can be refunded.</p>
                           
                       <p style={{textAlign:"justify", textJustify:"inter-word"}}><strong>b) Drones for agriculture advancements -</strong> Drones can be used on Crop field for scanning with compact multispectral imaging sensors, GPS map creation through onboard cameras, heavy payload transportation, and livestock monitoring with thermal-imaging camera-equipped drones.
High-income countries have seen recent improvements in their agricultural management systems through modern remote sensing technology, such as satellites and aircraft. 
This has led to better crop yields, higher quality produce and more sustainable agricultural practices.</p>

<h3 className="entry-sub-title">ICT for agricultHydroponics technologyure </h3>
<p style={{textAlign:"justify", textJustify:"inter-word"}}>Information Communication Technologies like podcasts, weblogs, social media platforms, e-books are constantly helping to bridge the information gap in the agriculture sector for farmers.</p>
<h3 className="entry-sub-title"> Hydroponics technology  </h3>
<p style={{textAlign:"justify", textJustify:"inter-word"}}>Hydroponics is a soilless farming technology that is used to grow vegetables and tomatoes.
It guarantees an all-year-round production for farmers and insulates these crops from the effects of climate change.</p>


                        </div>

					
					</div>
				</article>
				
				
				
	
				
			</div>
			<div className="col-md-12 col-lg-4">
			
				<div className="sidebar sidebar-right">
	
	<div className="widget widget_search">	
		{/* <form className="xs-serachForm" method="get" action="http://demo.xpeedstudio.com/html/charitypress/GET">
			<input type="search" name="search" placeholder="Type keywords...">
			<input type="submit" value="">
		</form> */}
	</div>


	
	<div className="widget widget_call_to_action">
		<a href="#" className="d-block">
			<img src="assets/images/side_add_baner.jpg" alt="" />
		</a>
	</div>
	


</div>			
			</div>
		</div>
	</div>
</div>


</main>
            <Footer/>
        </div>
    )
}
