import React from 'react'
import Navbar from '../NavBar/Navbar'
import Footer from '../Footer/Footer'

export default function Blogpost2() {
    return (
        <div>
            <Navbar/>
            <section className="xs-banner-inner-section parallax-window" style={{backgroundImage:"url('assets/image/blogs.jpg')"}}>
                <div className="xs-black-overlay"></div>
                <div className="container">
                    <div className="color-white xs-inner-banner-content">
                        <h2 className="text-white">Blog</h2>
                        {/* <p>Give a helping hand for poor people</p> */}
                        
                    </div>
                </div>
            </section>


            <main className="xs-main">
	
	<div className="xs-content-section-padding xs-blog-single">


	<div className="container">
		<div className="row">
			<div className="col-md-12 col-lg-8">
				 



                <article className="post format-standard hentry xs-blog-post-details">
					<div className="post-media post-image">
						<img src="assets/image/meat.jpg" className="img-responsive" alt="" />
					</div>

					<div className="post-body xs-border xs-padding-40">
						<div className="entry-header">
							<div className="post-meta row">
								<div className="col-md-2 xs-padding-0">
				 					<span className="post-meta-date"><b>07</b> Dec</span>
				 				</div>
								
							</div>
			 				<h2 className="entry-title xs-post-entry-title">
				 				<a href="#"> Meat Consumption in Meghalaya State.</a>
				 			</h2>
						</div>
						
						<div className="entry-content">
							<p style={{textAlign:"justify", textJustify:"inter-word"}}>Agricultural operations having limitations in Meghalaya due to its topography, climatic situation and
socio-economic conditions claiming only about 10% of the total land for cultivation, livestock and poultry
provide the only alternative avocation the villagers fall upon for a subsidiary living. Meghalaya is a hilly
State in the North Eastern Region of India between the plains of Assam in the North and Bangladesh in
the South. The State falls under the temperate zone which favours luxuriant growth of vegetation and
thick forests with rich varieties of flora and fauna. Meghalaya receives the direct influence of the South
West Monsoon originating from the Bay of Bengal and Arabian Sea . Generally, monsoon begins
sometimes in the month of April and continue till October. The topography, climate and Socio-economic
conditions makes the people to depend more on Animal Husbandry activities mainly because of
traditional agriculture in hilly areas allows only about 10% of the land in the State.</p>

							

							{/* <blockquote>
								Welcome to MONTH #4 of my Song of the Month project! In case you missed months one three, here's a little background on what I'm trying to do: I've released 6 albums list here available.
							</blockquote> */}

							
							
							{/* <h3 className="entry-sub-title">Farm-to-table selfies labore leggings:</h3> */}

							{/* <ul className="xs-unorder-list play">
								<li>Plaid fashion axe semiotics skateboard</li>
								<li>Mixtape fap Intelligentsia small batch placeat labore</li>
								<li>Gleams steal into the inner sanctuary grow</li>
								<li>Like these sweet mornings of spring which</li>
							</ul> */}

                            {/* <h3 className="entry-sub-title">History</h3> */}
                            <p style={{textAlign:"justify", textJustify:"inter-word"}}>In case of meat, the availability situation meets the requirement, taking the import from other States into
consideration.</p>
                            {/* <h3 className="entry-sub-title">Types of agricultural technology</h3> */}
                            {/* <p>Technologies and applications in agricultural technology include:</p> */}
                            <ul className="xs-unorder-list play">
								<li>Traditional dependency of rural tribal population in livestock for livelihood.</li>
								<li>Food habits of the people is mostly non-vegetarian in nature.</li>
								<li>Absence of any taboo for consumption of chicken meat, pork or beef.</li>
								<li>Absence of sentiments for slaughter of livestock including cattle for meat supply.</li>
                                <li>Readiness of the farmers to adopt modern technologies for breeding, feeding, management.</li>
                                <li>Availability of favourable markets within the State as well as in the adjoining States.</li>
                                <li>Availability of manpower for control of Livestock and Poultry diseases.</li>
                                {/* <li>Intelligent software analysis for pest and disease prediction, soil management and other involved analytical tasks</li>
                                <li>Biotech</li> */}
                            </ul>
                     
                            <p style={{textAlign:"justify", textJustify:"inter-word"}}>The Department of Animal Husbandry and Veterinary is entrusted with the responsibility of all aspect of
Livestock and Poultry Development, like, production, processing and marketing of livestock and poultry:-</p>
                            {/* <h3 className="entry-sub-title">Types of agricultural technology</h3> */}
                            {/* <p>Technologies and applications in agricultural technology include:</p> */}
                            <ul className="xs-unorder-list play">
								<li>Providing protection to livestock and poultry from the ravages of diseases through treatments and
preventive vaccinations.</li>
								<li>Introducing improved breeding techniques for upgrading the local stock.</li>
								<li>Ensuring better Animal Husbandry practices through adoption of Extension and Research.</li>
							
                                
                            </ul>
                     

                        </div>

					
					</div>
				</article>
				
				
				
	
				
			</div>
			<div className="col-md-12 col-lg-4">
			
				<div className="sidebar sidebar-right">
	
	<div className="widget widget_search">	
		{/* <form className="xs-serachForm" method="get" action="http://demo.xpeedstudio.com/html/charitypress/GET">
			<input type="search" name="search" placeholder="Type keywords...">
			<input type="submit" value="">
		</form> */}
	</div>


	
	<div className="widget widget_call_to_action">
		<a href="#" className="d-block">
			<img src="assets/images/side_add_baner.jpg" alt="" />
		</a>
	</div>
	


</div>			
			</div>
		</div>
	</div>
</div>


</main>
            <Footer/>
        </div>
    )
}
