import * as React from "react";
import HelmetHelper from "../../Helpers/HelmetHelper";
import {BellFilled} from '@ant-design/icons';

import { Menu, Dropdown, Modal, Tag, List, Avatar } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css'
import { Spin } from 'antd';

import * as firebase from "firebase";

export default function Navbar() {

	const [notifications, setNotifications] = React.useState([]);

    const loadNotifications = () => {
        firebase.firestore().collection('Notifications').get()
            .then( response => {
                const notifications = [];
                // console.log(response)
                response.forEach( notification => {
                    // console.log("NN...",notification.data())
                    const notice = notification.data();
                    notifications.push(notice);
                })
                setNotifications(notifications);
                // console.log(notifications);
            })
            .catch(error => console.log(error))
	};


	React.useEffect(() => {
        loadNotifications();
    }, []);

	const menus = (
		
		<List
			itemLayout="horizontal"
			dataSource={ notifications }
			renderItem={item => (
			<List.Item style={{margin :20, width:"300px"}}>
				<List.Item.Meta
					avatar={<Avatar src="assets/image/bell.jpg" />}
					title={<a href="https://ant.design">{item.notification}</a>}
					description={item.date}
				/>
				
			</List.Item>
			
			)}
		/>
		
	  );


    return (
        <div>
            <HelmetHelper/>
            <div id="preloader" style={{backgroundColor:"rgb(0, 230, 230)"}}>
				<div className="spinner" >
					<Spin/>
				</div>
			</div>
			

			<div className="xs-top-bar top-bar-second">
				<div className="container clearfix">
					<ul className="xs-top-social">
						
						{/* <li><a href="/"><i className="fa fa-twitter"></i></a></li> */}
						<li><li><a href="javascript:;" onClick={()=> window.open("https://www.facebook.com/ruralnomics/?hc_ref=ARSWwMA_3yNAR0wBmk6Zaj-TNJSNP_cXsWUfpDVsMuQHoTFdH16QuhW4-wt1Oa01QBc&ref=nf_target")} className="color-dribbble"><i className="fa fa-facebook"></i></a></li></li>
						<li><li><a href="javascript:;" onClick={()=> window.open("https://www.instagram.com/runo.fresh/")} className="color-dribbble"><i className="fa fa-instagram"></i></a></li></li>
						<li><a href="javascript:;" onClick = { ()=> window.open("https://www.linkedin.com/company/ruralonomics/") } className="color-linkedin"><i className="fa fa-linkedin"></i></a></li>
                                    <li><a href="javascript:;" onClick = { ()=> window.open("https://twitter.com/ruralnomics") } className="color-twitter"><i className="fa fa-twitter"></i></a></li>
						{/* <li>
							<Dropdown overlay={menus}>
								<a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
									<BellFilled/>
								</a>
							
							</Dropdown>
						</li> */}

						{/* <li><a href="#"><i className="fa fa-wordpress"></i></a></li>
						<li><a href="#"><i className="fa fa-slack"></i></a></li> */}
					</ul>
					
					<a href="mailto:info@ruralnomics.com" className="xs-top-bar-mail"><i className="fa fa-envelope-o"></i>info@ruralnomics.com</a>
				</div>
			</div>
			<header className="xs-header xs-fullWidth">
				<div className="container">
					<nav className="xs-menus">
						<div className="nav-header">
							<div className="nav-toggle"></div>
							<a href="/" className="xs-nav-logo" style={{  }}  >
								<img style={{width:"30%", height:"70%", borderRadius : 10}} src="assets/image/preLOader/re_logo_1.png" alt=""/>
							</a>
						</div>
						<div className="nav-menus-wrapper row">
							<div className="xs-logo-wraper col-lg-3">
								<a className="nav-brand" href="/">
									<img style={{width:"30%", height:"70%", borderRadius : 10}} src="assets/image/preLOader/re_logo_1.png" alt=""/>
								</a>
							</div>
							<div className="col-lg-9">
								<ul className="nav-menu">
									
									<li><a href="/">Home</a>
									</li>
									<li><a href="/about">About</a></li>
									<li><a href="/projects">Products</a></li>
									<li><a  style={{color:'#ff0000', fontSize: "16px"}} href="/gallery">Gallery</a></li>
									<li><a href="/board">Team</a></li>
									<li><a href="/partner">Partners</a></li>
									{/* <li><a href="/contact">Contact</a></li> */}
									<li><a href="/updates">Recognition</a></li>
									<li><a href="/blogpostmain">Blog</a></li>
									
								</ul>
							</div>
						</div>
					</nav>
				</div>
			</header>
        </div>
    )
}