import React from 'react'
import Navbar from '../NavBar/Navbar'
import Footer from '../Footer/Footer'

export default function Update() {
    return (
        <div>
            <Navbar/>
            {/* <section className="xs-banner-inner-section parallax-window" style={{backgroundImage:"url('assets/images/blog/blog_2.jpg')"}}>
	<div className="xs-black-overlay"></div>
	<div className="container">
		<div className="color-white xs-inner-banner-content">
			<h2>Blogs</h2>
			
		</div>
	</div>
</section> */}



<main className="xs-main">
	
	<section className="xs-content-section-padding">
	<div className="container">
	<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/15.jpg" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						<div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div>
						
						<h4 className="entry-title">
							<a href="/update1">

</a>
						</h4>
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>






			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/11.jpg" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						<div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									07 MARCH 2021
								</a>
							</span>
						</div>
						
						<h4 className="entry-title">
							<a href="/update1">
</a>
						</h4>
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>



			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/10.jpg" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						<div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 APRIL 2021
								</a>
							</span>
						</div>
						
						<h4 className="entry-title">
							<a href="/update1">
</a>
						</h4>
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>



			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/12.jpg" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						<div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									20 OCTOBER 2021
								</a>
							</span>
						</div>
						
						<h4 className="entry-title">
							<a href="/update1">
</a>
						</h4>
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/13.jpg" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						<div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									27 JANUARY 2021
								</a>
							</span>
						</div>
						
						<h4 className="entry-title">
							<a href="/update1">
</a>
						</h4>
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>

			
			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/14.jpg" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						<div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									15 MAY 2021
								</a>
							</span>
						</div>
						
						<h4 className="entry-title">
							<a href="/update1">
</a>
						</h4>
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


		
		</div>
        </section>
	

            </main>
            
            <Footer/>
        </div>
    )
}
