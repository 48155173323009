import React from 'react'
import Navbar from '../NavBar/Navbar'
import Footer from '../Footer/Footer'

export default function Blog() {
    return (
        <div>
            <Navbar/>
            <section className="xs-banner-inner-section parallax-window" style={{backgroundImage:"url('assets/image/bg_7.jpg')"}}>
                <div className="xs-black-overlay"></div>
                <div className="container">
                    <div className="color-white xs-inner-banner-content">
                        <h2 className="text-white">Team</h2>
                        {/* <p>Give a helping hand for poor people</p> */}
                        
                    </div>
                </div>
            </section>


            <main className="xs-main">
                <div className="xs-content-section-padding xs-blog-single">
                <div style={{justifyContent:'center', marginLeft:'20%'}}>
                    <div className="row">
                        <div className="col-md-12 col-lg-8" >
                            <article  className="post format-standard hentry xs-blog-post-details">
                                {/* <div className="post-media post-image">
                                    <img src="assets/images/blog/news-1.jpg" className="img-responsive" alt=""/>
                                </div> */}<br/><br/>

                                <div className="post-body xs-border xs-padding-40" style={{borderRadius:"15px", boxShadow:"13px 10px 5px #aaaaaa"}}>
                                    <div className="entry-header">
                                        <div className="post-meta row">
                                            <div className="col-md-3 xs-padding-0" >
                                                <div>
                                                <img src="assets/image/team/5.png" style={{height:"210px",width:"250px", borderRadius:10}} className="img-responsive" alt=""/>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        
                                        <h2 className="entry-title xs-post-entry-title">
                                            <a >
                                            Siddharth Das
                                            <br/>
                                            </a>
                                        </h2>
                                        <h5>Co-Founder & Director, Ruralnomics Pvt Ltd.  </h5>
                                    </div>
                                    
                                    <div className="entry-content">
                                       <h4 style={{textAlign:"justify", textJustify:"inter-word", fontStyle:"italic"}}>
                                       Siddharth Das is the Co-Founder and operating Director of Ruralnomics Pvt Ltd. Currently he is the President and one amongst the Founding Members of Stratemis HR Technologies.

                                        Siddharth has guided many teams in the past. He has worked collaboratively with the Management team of Quatrro Global Services for 14 years. Throughout his career, he has shared cordial relations with many great leaders in Manufacturing and Outsourcing industries respectively.

                                        With his 20 plus years of experience in Managing and scaling Operations and Technology, In Startups, IT, ITES and in Service sector; Siddharth has excelled in the field of HR & Operations, Consulting, Transformation and Technology.

                                        His strong passion for Machine Learning, Natural Language Processing, Sentiment Analysis along with other upcoming next-gen technologies, (especially in HR and Supply chain) has led to the conceptualisation of Stratemis HR Technologies where he is currently servicing clients across India, US and Europe.

                                        As a Management graduate from the University of Lincoln and a Post Graduate from Charles Stuart University, he specialises in subjects of International Business, HR and Information Technology . 
                                       </h4>
                                       
                                        {/* <h3 className="entry-sub-title">Farm-to-table selfies labore leggings:</h3> */}

                                    </div>
                                </div>
                            </article>

                            <article className="post format-standard hentry xs-blog-post-details">
                                {/* <div className="post-media post-image">
                                    <img src="assets/images/blog/news-1.jpg" className="img-responsive" alt=""/>
                                </div> */}<br/><br/>

                                <div className="post-body xs-border xs-padding-40" style={{borderRadius:"15px", boxShadow:"13px 10px 5px #aaaaaa"}}>
                                    <div className="entry-header">
                                        <div className="post-meta row">
                                            <div className="col-md-3 xs-padding-0" >
                                                <div>
                                                <img src="assets/image/team/chetna.png" style={{height:"220px",width:"280px", borderRadius:10}} className="img-responsive" alt=""/>
                                                </div>
                                            </div>
                                            
                                        </div>
                                       
                                        <h2 className="entry-title xs-post-entry-title">
                                            <a >
                                            Chetna Shridhar
                                            <br/>
                                            </a>
                                        </h2>
                                        <h5> Co-Founder & Director, Ruralnomics Pvt Ltd.  </h5>
                                    </div>
                                    
                                    <div className="entry-content">
                                      <h4 style={{textAlign:"justify", textJustify:"inter-word", fontStyle:"italic"}}>
                                      Chetna Shridhar is the Director and Co Founder of Ruralnomics Pvt Ltd

                                        An alumni from JNU, she is a behavioural scientist by profession.

                                        She holds advanced degrees in both, Sociology and Clinical Psychology. An educator by experience, Chetna has taught a range of subjects including Communication development, Interpretive Sociology and Broadcast Journalism and Media management to both Post graduates and undergraduates in one of the very renowned and old schools of journalism.

                                        Her assemblage also includes widespread experience in the field of HR, Consulting, Training and development, Content Management and Implementation of Media Strategies.

                                        As Chetna has ushered a Startup in past, dedicated to Furniture designing, She has plans to scale up Ruralnomics in the domain of Transformation in Skill development, Instructional Trainings, Content and Media management.

   
                                      </h4>
                                        
                                        {/* <h3 className="entry-sub-title">Farm-to-table selfies labore leggings:</h3> */}

                                    </div>
                                </div>
                            </article>


                            <article className="post format-standard hentry xs-blog-post-details">
                                {/* <div className="post-media post-image">
                                    <img src="assets/images/blog/news-1.jpg" className="img-responsive" alt=""/>
                                </div> */}<br/><br/>

                                <div className="post-body xs-border xs-padding-40" style={{borderRadius:"15px", boxShadow:"13px 10px 5px #aaaaaa"}}>
                                    <div className="entry-header">
                                        <div className="post-meta row">
                                            <div className="col-md-3 xs-padding-0" >
                                                <div>
                                                <img src="assets/image/team/shailang.png" style={{height:"210px",width:"250px", borderRadius:10}} className="img-responsive" alt=""/>
                                                </div>
                                            </div>
                                            
                                        </div>
                                       
                                        <h2 className="entry-title xs-post-entry-title">
                                            <a >
                                            Shailang Marbaniang
                                            <br/>
                                            </a>
                                        </h2>
                                        <h5>Director, Ruralnomics Pvt Ltd.  </h5>
                                    </div>
                                    
                                    <div className="entry-content">
                                      <h4 style={{textAlign:"justify", textJustify:"inter-word", fontStyle:"italic"}}>
                                      Shailang is a young Entrepreneur of Meghalaya. He completed his D.Pharm from Sikkim Himalayan Institute.
                                      Shailang spent about 6 years as a Registered Pharmacist under the Govt. Meghalaya. This experience gave him great learning in the field of Inventory Management, Supply Operation, Logistics management and devising Stock Inventory Planning and Pricing .
                                       His short stint, but very meaningful experience in the government sector helped him to use his experiential skill to promote and run his State Registered Retail Pharmacy in Shillong, Meghalaya for the past 12 years. He is the Founder and Promoter of the brand .
                                        Shailang brings in 18 years of rock solid experience in supply management, stakeholder management and operation skills to Ruralnomics. Shailang is the third director of Ruralnomics and works very closely with Siddharth and the Operation team.
   
                                      </h4>
                                        
                                        {/* <h3 className="entry-sub-title">Farm-to-table selfies labore leggings:</h3> */}

                                    </div>
                                </div>
                            </article>

                            <article className="post format-standard hentry xs-blog-post-details">
                                {/* <div className="post-media post-image">
                                    <img src="assets/images/blog/news-1.jpg" className="img-responsive" alt=""/>
                                </div> */}<br/><br/>

                                <div className="post-body xs-border xs-padding-40" style={{borderRadius:"15px", boxShadow:"13px 10px 5px #aaaaaa"}}>
                                    <div className="entry-header">
                                        <div className="post-meta row">
                                            <div className="col-md-3 xs-padding-0" >
                                                <div>
                                                <img src="assets/image/team/elvee.jpeg" style={{height:"210px",width:"250px", borderRadius:10}} className="img-responsive" alt=""/>
                                                </div>
                                            </div>
                                            
                                        </div>
                                       
                                        <h2 className="entry-title xs-post-entry-title">
                                            <a >
                                            Dr Elvee Silcheng Marbaniang
                                            <br/>
                                            </a>
                                        </h2>
                                        <h5>Director, Ruralnomics Pvt Ltd.  </h5>
                                    </div>
                                    
                                    <div className="entry-content">
                                      <h4 style={{textAlign:"justify", textJustify:"inter-word", fontStyle:"italic"}}>
                                      Dr Elvee Silcheng Marbaniang is a young dynamic practising General Physician, an Alumnus
                                    of one of the top most prestigious Medical Institutes of India, Christian Medical College
                                    Vellore. Dr Elvee with his years of association with this premiere institute, upholds the
                                    values of what it stands for, where he strongly beliefs in “Service first- above all”. Dr Elvee
                                    has working experience and association with various Secondary and Tertiary Medical
                                    Institutions in the state of Meghalaya for a short but insightful 5-6 years.  His exposure to
                                    the healthcare challenges in rural and urban set up gives him a clearer picture of what
                                    Runohealth&#39;s vision and objective should be. His charismatic and energetic persona along
                                    with a strong understanding of changes that are required in the health sector, enables the
                                    entire team under Runohealth to be mission and vision driven with the sole objective of
                                    achieving their goal. Dr Elvee S Marbaniang with his background as a general physician is in
                                    a better position to understand your Healthcare problems and the required solutions that
                                    Runohealth and it&#39;s team are committed to. Dr Elvee is one of the co founder and Director
                                    of Runohealth, with a strong commitment towards the mission and vision of our healthcare
                                    platform.
   
                                      </h4>
                                        
                                        {/* <h3 className="entry-sub-title">Farm-to-table selfies labore leggings:</h3> */}

                                    </div>
                                </div>
                            </article>

                            <article className="post format-standard hentry xs-blog-post-details">
                                {/* <div className="post-media post-image">
                                    <img src="assets/images/blog/news-1.jpg" className="img-responsive" alt=""/>
                                </div> */}<br/><br/>

                                <div className="post-body xs-border xs-padding-40" style={{borderRadius:"15px", boxShadow:"13px 10px 5px #aaaaaa"}}>
                                    <div className="entry-header">
                                        <div className="post-meta row">
                                            <div className="col-md-3 xs-padding-0" >
                                                <div>
                                                <img src="assets/image/team/3.jpg" style={{height:"200px",width:"250px", borderRadius:10}} className="img-responsive" alt=""/>
                                                </div>
                                            </div>
                                            
                                        </div>
                                       
                                        <h2 className="entry-title xs-post-entry-title">
                                            <a >
                                            Mirlang Phanbuh
                                            <br/>
                                            </a>
                                        </h2>
                                        <h5> Chief Strategy Officer, Ruralnomics Pvt Ltd.  </h5>
                                    </div>
                                    
                                    <div className="entry-content">
                                      <h4 style={{textAlign:"justify", textJustify:"inter-word", fontStyle:"italic"}}>
                                      A graduate from Madras Christian College and a Postgraduate form RCBS, a logistics
                                        professional with over 9 years of experience. Mirlang has been involved with various
                                        departments of Logistics ranging from Air & Sea Freight Shipping, Warehousing, Fulfilment
                                        Centre Management, Fleet management, Last mile & First mile operation, Manpower
                                        management and Operational expansion, especially in the North Eastern region.
                                        Having worked for companies like Fedex & DHL (Chennai/ Hyderabad) he ventured many big
                                        opportunities.
                                        At Paytm, Mirlang was involved with Warehousing & Fulfilment Centre management and also
                                        for the expansion of these Fulfilment centres.
                                        While at Delhivery he handled the Fleet management and was involved in its expansion.
                                        During his stint at Swiggy, Mirlang was a part of the team that rebuilt the business in the
                                        Shillong city and was handling the overall operations.
                                        Being a part of these big companies he has had the opportunity to explore work across different
                                        functions.
                                      </h4>
                                        
                                        {/* <h3 className="entry-sub-title">Farm-to-table selfies labore leggings:</h3> */}

                                    </div>
                                </div>
                            </article>

                            <article className="post format-standard hentry xs-blog-post-details">
                                {/* <div className="post-media post-image">
                                    <img src="assets/images/blog/news-1.jpg" className="img-responsive" alt=""/>
                                </div> */}<br/><br/>

                                <div className="post-body xs-border xs-padding-40" style={{borderRadius:"15px", boxShadow:"13px 10px 5px #aaaaaa"}}>
                                    <div className="entry-header">
                                        <div className="post-meta row">
                                            <div className="col-md-3 xs-padding-0" >
                                                <div>
                                                <img src="assets/image/team/1.jpeg" style={{height:"190px",width:"270px", borderRadius:10}} className="img-responsive" alt=""/>
                                                </div>
                                            </div>
                                            
                                        </div>
                                       
                                        <h2 className="entry-title xs-post-entry-title">
                                            <a >
                                            Nirupam Chakraborty
                                            <br/>
                                            </a>
                                        </h2>
                                        <h5> Manager Finance, Ruralnomics Pvt Ltd.  </h5>
                                    </div>
                                    
                                    <div className="entry-content">
                                      <h4 style={{textAlign:"justify", textJustify:"inter-word", fontStyle:"italic"}}>
                                      A tax consultant for close to 20 years of experience. Worked with numerous
                                        businesses for the finance and accounting. A post-graduate in commerce with a degree in Law
                                        (LLB) from Gauhati University, runs his own accounting firm with the name of “SreejiTax

                                        Consulting”, Guwahati.
                                        Nirupam has deep knowledge and understanding about company compliance.
                                        His expertise is not limited to accounts only, as he also works as an independent legal advisor.
                                      </h4>
                                        
                                        {/* <h3 className="entry-sub-title">Farm-to-table selfies labore leggings:</h3> */}

                                    </div>
                                </div>
                            </article>

                            <article className="post format-standard hentry xs-blog-post-details">
                                {/* <div className="post-media post-image">
                                    <img src="assets/images/blog/news-1.jpg" className="img-responsive" alt=""/>
                                </div> */}<br/><br/>

                                <div className="post-body xs-border xs-padding-40" style={{borderRadius:"15px", boxShadow:"13px 10px 5px #aaaaaa"}}>
                                    <div className="entry-header">
                                        <div className="post-meta row">
                                            <div className="col-md-3 xs-padding-0" >
                                                <div>
                                                <img src="assets/image/team/advisor.png" style={{height:"190px",width:"270px", borderRadius:10}} className="img-responsive" alt=""/>
                                                </div>
                                            </div>
                                            
                                        </div>
                                       
                                        <h2 className="entry-title xs-post-entry-title">
                                            <a >
                                            Kyrmenlang Uriah
                                            <br/>
                                            </a>
                                        </h2>
                                        <h5> Advisor, Ruralnomics Pvt Ltd.  </h5>
                                    </div>
                                    
                                    <div className="entry-content">
                                      <h4 style={{textAlign:"justify", textJustify:"inter-word", fontStyle:"italic"}}>
                                      Kyrmenlang worked as a news reporter for the Meghalaya Times in Shillong (2008-2010), before joining the city's weekly TV channel Ri Khasi Channel (2010–2011). In 2011, he joined Frontier Television in Guwahati till 2014 before working with NewsX TV as a stringer. After five years he branched out into freelance journalism, obtaining assignments as a reporter and correspondent with U Peitngor, Tura Times, Batesi TV, the Netherland TV agency Zoomin.tv in 2015 and 101 Reporters till date.

 

In 2015, he was offered a full-fledged correspondent position by Batesi TV. He has also interviewed famous figures from the State and has published multiple reports, many of which involved in-depth investigative reporting, condensed into 40 minute news copies. 

 

In March, 2016, Kyrmenlang was promoted to "Editor in Chief" for Batesi TV and in August of the same year, he took over as Editor of the vernacular daily, U Peitngor. 

 

He is the Founder President of Meghalaya Electronic Media Association (MEMA) and the present Organising Secretary of Meghalaya Editor’s and Publisher’s Association (MEPA).

 

In 2016, Kyrmenlang took over the post of Editor-in-chief at U Peitngor. In Batesi TV, he hosted a show titled 'Markhmat bad ki Riewkhmat,' that features interviews with Meghalaya's renowned political leaders. He was also the first journalist to investigate and report on the controversial issue of flesh trade operations occurring in hotels and guest houses in the capital city of Shillong.

 

At present, he heads the media team at Meghalaya Legislative Assembly Channel and hosts a show on the Shillong Mail YouTube Channel called 'Na ka mynta, Sha Ka Lashai.'

 
                                      </h4>
                                        
                                        {/* <h3 className="entry-sub-title">Farm-to-table selfies labore leggings:</h3> */}

                                    </div>
                                </div>
                            </article>


                        </div>
                    </div>
                </div>
            </div>	

            </main>
            <Footer/>
        </div>
    )
}
