import * as React from "react";
import * as firebase from 'firebase';
import HelmetHelper from "../../Helpers/HelmetHelper";
import Navbar from "../NavBar/Navbar";
import Footer from "../Footer/Footer";

import { Drawer, Form, Button, Col, Row, Input, notification, DatePicker, Select, Modal} from 'antd';


import 'antd/dist/antd.css';

export default function Home(this: any, {authService}) {


    return (
        <div>
            <HelmetHelper/>
			<Navbar/>

			<section className="xs-welcome-slider">
				<div className="xs-banner-slider owl-carousel">
					<div className="xs-welcome-content" style={{backgroundImage: "url(assets/image/bg_1.jpg)"}}>
						<div className="container row">
							<div className="xs-welcome-wraper banner-verion-2 color-white  content-left">
							{/* <img src="assets/images/welcome-text-1.png" alt=""/> */}
								<p>LOCAL FOR LOCAL</p>
								<h2 style={{color : '#fff', fontWeight:"bold",fontSize:"6vw"}}>RURALNOMICS</h2>
								<div className="xs-btn-wraper">
									<a href="javascript:;" onClick = { ()=> window.open("/contact") } className="btn btn-primary">
										<span className=""><i className="fa fa-heart"></i></span> Contact Us
									</a>
								</div>
							</div>
						</div>
						<div className="xs-black-overlay"></div>
					</div>
					<div className="xs-welcome-content" style={{backgroundImage: "url(assets/image/F.png)"}}>
						<div className="container row">
							<div className="xs-welcome-wraper banner-verion-2 color-white  content-left">
							{/* <img src="assets/images/welcome-text-1.png" alt=""/> */}
								{/* <p>BRIDGING THE GAP</p> */}
								<h2 style={{color : '#fff', fontWeight:"bold",fontSize:"6vw"}}>RURALNOMICS</h2>
								<div className="xs-btn-wraper">
									<a href="javascript:;" onClick = { ()=> window.open("/contact") } className="btn btn-primary">
										<span className=""><i className="fa fa-heart"></i></span> Contact Us
									</a>
								</div>
								{/* <div className="xs-btn-wraper">
									<a  style={{color : '#00b3b3'}} onClick={ ()=>setIsDrawerVisible(true) } className="btn btn-outline-primary">
										Registration
									</a>
									<a href="javascript:;" onClick = { ()=> window.open("https://instamojo.com/@eljeffa") } className="btn btn-primary">
										<span className="badge"><i className="fa fa-heart"></i></span> Donate Now
									</a>
								</div> */}
							</div>
						</div>
						<div className="xs-black-overlay"></div>
					</div>
					<div className="xs-welcome-content" style={{backgroundImage: "url(assets/image/bg_2.jpg)"}}>
						<div className="container row">
							<div className="xs-welcome-wraper banner-verion-2 color-white  content-left">
							{/* <img src="assets/images/welcome-text-1.png" alt=""/> */}
								<p>BRIDGING THE GAP</p>
								<h2 style={{color : '#fff', fontWeight:"bold",fontSize:"6vw"}}>RURALNOMICS</h2>
								<div className="xs-btn-wraper">
									<a href="javascript:;" onClick = { ()=> window.open("/contact") } className="btn btn-primary">
										<span className=""><i className="fa fa-heart"></i></span> Contact Us
									</a>
								</div>
								{/* <div className="xs-btn-wraper">
									<a  style={{color : '#00b3b3'}} onClick={ ()=>setIsDrawerVisible(true) } className="btn btn-outline-primary">
										Registration
									</a>
									<a href="javascript:;" onClick = { ()=> window.open("https://instamojo.com/@eljeffa") } className="btn btn-primary">
										<span className="badge"><i className="fa fa-heart"></i></span> Donate Now
									</a>
								</div> */}
							</div>
							
						</div>
						<div className="xs-black-overlay"></div>
					</div>
					<div className="xs-welcome-content" style={{backgroundImage: "url(assets/image/bg_10.jpg)"}}>
						<div className="container row">
							<div className="xs-welcome-wraper banner-verion-2 color-white  content-left">
							{/* <img src="assets/images/welcome-text-1.png" alt=""/> */}
								<p>BE THE CHANGE YOU WISH TO SEE</p>
								<h2 style={{color : '#fff', fontWeight:"bold",fontSize:"6vw"}}>RURALNOMICS</h2>
								<div className="xs-btn-wraper">
									<a href="javascript:;" onClick = { ()=> window.open("/contact") } className="btn btn-primary">
										<span className=""><i className="fa fa-heart"></i></span> Contact Us
									</a>
								</div>
								{/* <div className="xs-btn-wraper">
									<a  style={{color : '#00b3b3'}} onClick={ ()=>setIsDrawerVisible(true) } className="btn btn-outline-primary">
										Registration
									</a>
									<a href="javascript:;" onClick = { ()=> window.open("https://instamojo.com/@eljeffa") } className="btn btn-primary">
										<span className="badge"><i className="fa fa-heart"></i></span> Donate Now
									</a>
								</div> */}
							</div>
						</div>
						<div className="xs-black-overlay"></div>
					</div>
					{/* <div className="xs-welcome-content" style={{backgroundImage: "url(assets/images/slider/slider_6.jpg)"}}>
						<div className="container row">
							<div className="xs-welcome-wraper banner-verion-2 color-white col-md-8 content-left">
								<p>Charity for childrens</p>
								<h2 style={{color : '#fff'}}>Hunger is stalking the globe</h2>
								<div className="xs-btn-wraper">
									<a style={{color : '#000'}} onClick={ ()=>setIsDrawerVisible(true) }className="btn btn-outline-primary">
										join us now
									</a>
									<a href="#" className="btn btn-primary">
										<span className="badge"><i className="fa fa-heart"></i></span> Donate Now
									</a>
								</div>
							</div>
						</div>
						<div className="xs-black-overlay"></div>
					</div> */}
				</div>
			</section>


			<section className="xs-section-padding">
				<div className="container">
					<div className="row">
						<div className="col-lg-7">
							<div className="xs-feature-text-content">
								<div className="xs-heading">
									{/* <h2>About EL JEFFA FOUNDATION</h2> */}
									<h3 className="xs-title" data-title="ABOUT">About RURALNOMICS</h3>
									<span className="xs-separetor bg-bondiBlue"></span>
								</div>
								<p style={{fontSize:"20px",textAlign:"justify", textJustify:"inter-word"}}><strong><span className="color-orange">Ruralnomics Pvt Ltd 
                                </span> </strong> is a young Ecommerce and Supply Startup with a vision to enter into
													the Agri Tech Industry.
													A team of professionals, who thought and envisioned that Agriculture, Supply and E-
													Commerce are areas that could be served and serviced locally.
													This gave birth to a bootstrap strategic organization, with a clear vision to transform the E
													Commerce and Supply space of North East and uplift the Agriculture Sector.
</p>
								<a href="/about" className="btn btn-secondary bg-bondiBlue">see more</a>
							</div>
						</div>
						<div className="col-lg-5">
							<div className="xs-feature-image-box image-1">
								<img src="assets/image/home3.jpeg" alt="" style={{height:"200px", width:"280px"}}/>
							</div>
							<div className="xs-feature-image-box image-2">
								<img src="assets/image/home1.jpeg" alt="" style={{height:"250px", width:"380px"}}/>
							</div>
						</div>
					</div>
				</div>
			</section>


			<section className="bg-gray waypoint-tigger xs-section-padding xs-popularCauses-v2">
				<div className="container">
					<div className="xs-heading row xs-mb-60">
						<div className="col-md-9 col-xl-9">
							<h2 className="xs-title">Our Products</h2>
							{/* <span className="xs-separetor dashed"></span> */}
							{/* <p>FundPress has built a platform focused on aiding entrepreneurs, startups, and <br/> companies raise capital from anyone.</p> */}
						</div>
						<div className="col-xl-3 col-md-3 xs-btn-wraper">
							<a href="/projects" className="btn btn-primary bg-orange">all products</a>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4 col-md-6">
							<div className="xs-popular-item xs-box-shadow">
								<div className="xs-item-header">

									<img src="assets/image/agrifresh.jpg" alt="" style={{height:"230px", width:"500px"}}/>

									<div className="xs-skill-bar">
										{/* <div className="xs-skill-track bg-light-red">
											<p><span className="number-percentage-count number-percentage" data-value="90" data-animation-duration="3500">0</span>%</p>
										</div> */}
									</div>
								</div>
								<div className="xs-item-content">
									<ul className="xs-simple-tag xs-mb-20">
										<li><a className="color-light-red" href="/projects">Garden and Food</a></li>
									</ul>

									<a href="projects" className="xs-post-title xs-mb-20">RunoFresh</a>

									<p className="xs-list-with-content"  style={{textAlign:"justify", textJustify:"inter-word"}} >
										{/* <li>$67,000<span>Pledged</span></li>
										<li><span className="number-percentage-count number-percentage" data-value="90" data-animation-duration="3500">0</span>% <span>Funded</span></li>
										<li>3<span>Days to go</span></li> */}
										As its first vision story implementation, Ruralnomics launched its first B2B and B2C product category by the name of #RunoFresh.
										RunoFresh quickly acquired its brand presence in Guwahati, Dibrughar, Patshala and Shillong.  
									</p><br/><br/><br/><a href="/projects"><h6 style={{color:'#ff0000', fontSize:'17px'}}>Learn More &#8594;</h6></a>
												
									{/* <span className="xs-separetor"></span>
									
									<div className="row xs-margin-0">
										<div className="xs-round-avatar">
											<img src="assets/images/avatar/avatar_1.jpg" alt=""/>
										</div>
										<div className="xs-avatar-title">
											<a href="/about"><span></span>-READ MORE</a>
										</div>
									</div> */}
								</div>
							</div>
						</div>

					

						<div className="col-lg-4 col-md-6">
							<div className="xs-popular-item xs-box-shadow">
								<div className="xs-item-header">

									<img src="assets/image/runoagri.jpg" style={{height:"220px", width:"500px"}} alt=""/>

									<div className="xs-skill-bar">
										{/* <div className="xs-skill-track bg-riptide">
											<p><span className="number-percentage-count number-percentage" data-value="74" data-animation-duration="3500">0</span>%</p>
										</div> */}
									</div>
								</div>
								<div className="xs-item-content">
									<ul className="xs-simple-tag xs-mb-20">
										<li><a className="color-riptide" href="#">Awareness</a></li>
									</ul>

									<a href="/projects" className="xs-post-title xs-mb-30">RunoAgri</a>
									
									<p className="xs-list-with-content" style={{textAlign:"justify", textJustify:"inter-word"}}>
									RunoAgri is a very unique OTT and Predictive Platform for organic farming. The product will work as a strong integration between farmers, 
									State Bodies, International Councils  and Sensor based App to help farmers and organic producers with predictive data to improve productivity.
									</p><a href="/projects"><h6 style={{color:'#ff0000', fontSize:'17px'}}>Learn More &#8594;</h6></a>
									
								</div>
							</div>
						</div>

						<div className="col-lg-4 col-md-6">
							<div className="xs-popular-item xs-box-shadow">
								<div className="xs-item-header">

									<img src="assets/image/000.jpg" style={{height:"220px", width:"380px"}} alt=""/>

									<div className="xs-skill-bar">
										{/* <div className="xs-skill-track bg-orange">
											<p><span className="number-percentage-count number-percentage" data-value="100" data-animation-duration="3500">0</span>%</p>
										</div> */}
									</div>
								</div>
								<div className="xs-item-content">
									<ul className="xs-simple-tag xs-mb-30">
										<li><a className="color-orange" href="#">Environment</a></li>
									</ul>

									<a href="/projects" className="xs-post-title xs-mb-50">RunoFarm</a>

									<p className="xs-list-with-content" style={{textAlign:"justify", textJustify:"inter-word"}}>
									RunoFarm is a unique farm utility product that will enable farmer to get an access to the latest farm equipment nationally or internationally, through our RunoFarm Platform.
									</p><br/><a href="/projects"><h6 style={{color:'#ff0000', fontSize:'17px'}}>Learn More &#8594;</h6></a>
									
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>


			<section className="xs-section-padding">
				<div className="container">
					<div className="row">
						<div className="col-md-12 col-lg-6">
							<div className="xs-text-content xs-pr-20">
								<h1 className="color-navy-blue">Ruralnomics Vision</h1>
								{/* <p>The CharityPress community was named a “Top 25 Best Global Philanthropist” by Barron’s. We beat Oprah. And, Mashable named CharityPress something like “the best place to raise money online for your favorite causes.”</p> */}
								<blockquote>
								<h2>The vision is “For Local, By Local”.</h2>
								</blockquote>
								{/* <a href="javascript:;" onClick = { ()=> window.open("https://instamojo.com/@eljeffa") } className="btn btn-primary bg-light-red">
									<span className="badge"><i className="fa fa-heart"></i></span> Donate Now
								</a> */}
							</div>
						</div>
						<div className="col-md-6 col-lg-3">
							<div className="xs-feature-image">
								<img src="assets/image/111.jpeg" style={{borderRadius:12 ,boxShadow:"13px 10px 5px #aaaaaa"}} alt=""/>
							</div>
						</div>
						<div className="col-md-6 col-lg-3">
							<div >
								<img src="assets/image/222.jpeg" style={{borderRadius:12 ,boxShadow:"13px 10px 5px #aaaaaa"}} alt=""/>
							</div>
						</div>
					</div>
				</div>
			</section>

		
			{/* <section className="bg-gray xs-partner-section">
	<div className="container">
		<div className="row">
			<div className="col-lg-5">
				<div className="xs-partner-content">
					<div className="xs-heading xs-mb-40">
						<h2 className="xs-mb-0 xs-title">PARTNERS.</h2>
					</div>
					
					
				</div>
			</div>
			<div className="col-lg-7">
				<ul className="fundpress-partners">
					<li><a href="#"><img src="assets/images/partner/client_1.png" alt=""/></a></li>
					<li><a href="#"><img src="assets/images/partner/client_2.png" alt=""/></a></li>
					<li><a href="#"><img src="assets/images/partner/client_3.png" alt=""/></a></li>
					<li><a href="#"><img src="assets/images/partner/client_4.png" alt=""/></a></li>
					<li><a href="#"><img src="assets/images/partner/client_5.png" alt="" /></a></li>
				</ul>
			</div>
		</div>
	</div>
</section> */}

			
			<Footer/>

			
					
        </div>
    )
}