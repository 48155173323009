import {compose} from "redux";
import {map, mount, route, withView} from "navi";
import {View} from "react-navi";
import Layout from "../Components/Layout/Layout";
import Home from "../Pages/Home/Home";
import React from "react";
import About from "../Pages/About/About";
import Projects from "../Pages/Projects/Projects";
import Contact from "../Pages/Contact/Contact";
import Blog from "../Pages/Blog/Blog";
import Gallery from "../Pages/Gallery/Gallery";
import Blogpost from "../Pages/Blogs/Blogpost";
import Blogpostmain from "../Pages/Blogs/Blogpostmain";
import Blogpost1 from "../Pages/Blogs/Blogpost1";
import Blogpost2 from "../Pages/Blogs/Blogpost2";
import Update from "../Pages/Update/update";
import Update1 from "../Pages/Update/update1";
import UpdateMenu from "../Pages/Update/updatemenu";

export default compose<any>(
    withView((request, context: any, arg) => <Layout authService={context.authService}>
        <View />
    </Layout>),
    mount({
        '/': map((request, context: any) => route({
            title: 'Home',
            view: <Home authService={context.authService} />
        })),
        '/about': map((request, context: any) => route({
            title: 'About',
            view: <About/>
        })),
        '/contact' : map((request, context:any) => route({
            title:'Contact',
            view : <Contact/>
        })),
        '/projects' : map((request, context:any) => route({
            title:'Projects',
            view : <Projects/>
        })),
        '/board' : map((request, context:any) => route({
            title:'Blog',
            view : <Blog/>
        })),
        '/blogpost' : map((request, context:any) => route({
            title:'Blogpost',
            view : <Blogpost/>
        })),
        '/blogpostmain' : map((request, context:any) => route({
            title:'Blogpostmain',
            view : <Blogpostmain/>
        })),
        '/blogpost1' : map((request, context:any) => route({
            title:'Blogpost1',
            view : <Blogpost1/>
        })),
        '/blogpost2' : map((request, context:any) => route({
            title:'Blogpost2',
            view : <Blogpost2/>
        })),
        '/updates' : map((request, context:any) => route({
            title:'Update',
            view : <Update/>
        })),
        '/update1' : map((request, context:any) => route({
            title:'Update1',
            view : <Update1/>
        })),
        '/partner' : map((request, context:any) => route({
            title:'UpdateMenu',
            view : <UpdateMenu/>
        })),
        '/gallery' : map((request, context:any) => route({
            title:'Gallery',
            view : <Gallery/>
        }))
    })
)




