import React from 'react'
import Navbar from '../NavBar/Navbar'
import Footer from '../Footer/Footer'

export default function Blogpostmain() {
    return (
        <div>
            <Navbar/>
            {/* <section className="xs-banner-inner-section parallax-window" style={{backgroundImage:"url('assets/images/blog/blog_2.jpg')"}}>
	<div className="xs-black-overlay"></div>
	<div className="container">
		<div className="color-white xs-inner-banner-content">
			<h2>Blogs</h2>
			
		</div>
	</div>
</section> */}



<main className="xs-main">
	
	<section className="xs-content-section-padding">
	<div className="container">
		{/* <div className="row xs-mb-50">
			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal xs-mb-30"></div>
					<div className="entry-thumbnail ">
						<img src="assets/images/blog/blog_1.jpg" alt="" />
						<div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/images/avatar/avatar_1.jpg" alt="" />
							</span>
							<span className="author-name">
								<a href="#">By Simona</a>
							</span>
						</div>
					</div>
					<div className="entry-header">
						<div className="entry-meta">
							<span className="date">
								<a href="#"  rel="bookmark" className="entry-date">
									27th August 2017
								</a>
							</span>
						</div>
						
						<h4 className="entry-title">
							<a href="#">Brilliant After All, A New Album by Rebecca: Help poor people</a>
						</h4>
					</div>
					<span className="xs-separetor"></span>
					<div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div>
				</div>
			</div> */}
			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/festival.jpg" alt=""  />
						<div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt=""/>
							</span>
							<span className="author-name">
								<a href="/blogpost">By Ruralnomics Team</a>
							</span>
						</div>
					</div>
					<div className="entry-header">
						<div className="entry-meta">
							<span className="date">
								<a href="/blogpost"  rel="bookmark" className="entry-date">
									02 July 2020
								</a>
							</span>
						</div>
						
						<h4 className="entry-title">
							<a href="/blogpost">Most Famous Festivals in North East India.
</a>
						</h4>
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>
			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/agrotecheconomy.jpg" alt="" style={{height:"230px", width:"500px"}}/>
						<div className="post-author">
							<span className="xs-round-avatar">
                            <img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt=""/>
							</span>
							<span className="author-name">
								<a href="/blogpost1">By Ruralnomics Team</a>
							</span>
						</div>
					</div>
					<div className="entry-header">
						<div className="entry-meta">
							<span className="date">
								<a href="/blogpost1"  rel="bookmark" className="entry-date">
									13 October 2020
								</a>
							</span>
						</div>
						
						<h4 className="entry-title" style={{paddingTop:"25px"}}>
							<a href="/blogpost1"> What is Agro-Tech Economy?</a>
						</h4>
                        
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>
			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/meat.jpg" alt="" style={{height:"230px", width:"500px"}}/>
						<div className="post-author">
							<span className="xs-round-avatar">
                            <img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt=""/>
							</span>
							<span className="author-name">
								<a href="/blogpost2">By Ruralnomics Team</a>
							</span>
						</div>
					</div>
					<div className="entry-header">
						<div className="entry-meta">
							<span className="date">
								<a href="/blogpost2"  rel="bookmark" className="entry-date">
									07 DECEMBER 2020
								</a>
							</span>
						</div>
						
						<h4 className="entry-title" >
							<a href="/blogpost2"> Meat Consumption in Meghalaya State.</a>
						</h4>
                        
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>
			{/* <div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/images/blog/blog_4.jpg" alt="" />
						<div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/images/avatar/avatar_4.jpg" alt="" />
							</span>
							<span className="author-name">
								<a href="#">By Evan</a>
							</span>
						</div>
					</div>
					<div className="entry-header">
						<div className="entry-meta">
							<span className="date">
								<a href="#"  rel="bookmark" className="entry-date">
									27th August 2017
								</a>
							</span>
						</div>
						
						<h4 className="entry-title">
							<a href="#">Brilliant After All, A New Album by Rebecca: Help poor people</a>
						</h4>
					</div>
					<span className="xs-separetor"></span>
					<div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div>
				</div>
			</div> */}
			{/* <div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/images/blog/blog_5.jpg" alt="" />
						<div className="post-author">
							<span className="xs-round-avatar">
                            <img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt=""/>
							</span>
							<span className="author-name">
								<a href="#">By Ruralnomics Team</a>
							</span>
						</div>
					</div>
					<div className="entry-header">
						<div className="entry-meta">
							<span className="date">
								<a href="#"  rel="bookmark" className="entry-date">
									02 May 2017
								</a>
							</span>
						</div>
						
						<h4 className="entry-title">
							<a href="#">South african pre primary school build for children</a>
						</h4>
					</div>
					<span className="xs-separetor"></span>
				
				</div>
			</div> */}
			{/* <div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/images/blog/blog_6.jpg" alt="" />
						<div className="post-author">
							<span className="xs-round-avatar">
                            <img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt=""/>
							</span>
							<span className="author-name">
								<a href="#">By Jocky</a>
							</span>
						</div>
					</div>
					<div className="entry-header">
						<div className="entry-meta">
							<span className="date">
								<a href="#"  rel="bookmark" className="entry-date">
									13 January 2017
								</a>
							</span>
						</div>
						
						<h4 className="entry-title">
							<a href="#">Provide pure water for syrian poor people</a>
						</h4>
					</div>
					<span className="xs-separetor"></span>
					<div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div>
				</div>
			</div>
			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal">
					<div className="entry-thumbnail ">
						<img src="assets/images/blog/blog_7.jpg" alt="" />
						<div className="post-author">
							<span className="xs-round-avatar">
                            <img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt=""/>
							</span>
							<span className="author-name">
								<a href="#">By Kalina</a>
							</span>
						</div>
					</div>
					<div className="entry-header">
						<div className="entry-meta">
							<span className="date">
								<a href="#"  rel="bookmark" className="entry-date">
									27th August 2017
								</a>
							</span>
						</div>
						
						<h4 className="entry-title">
							<a href="#">Brilliant After All, A New Album by Rebecca: Help poor people</a>
						</h4>
					</div>
					<span className="xs-separetor"></span>
					<div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div>
				</div>
			</div> */}
			{/* <div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal">
					<div className="entry-thumbnail ">
						<img src="assets/images/blog/blog_8.jpg" alt="" />
						<div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/images/avatar/avatar_8.jpg" alt="" />
							</span>
							<span className="author-name">
								<a href="#">By Killer</a>
							</span>
						</div>
					</div>
					<div className="entry-header">
						<div className="entry-meta">
							<span className="date">
								<a href="#"  rel="bookmark" className="entry-date">
									02 May 2017
								</a>
							</span>
						</div>
						
						<h4 className="entry-title">
							<a href="#">South african pre primary school build for children</a>
						</h4>
					</div>
					<span className="xs-separetor"></span>
					<div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div>
				</div>
			</div> */}
			{/* <div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal">
					<div className="entry-thumbnail ">
						<img src="assets/images/blog/blog_9.jpg" alt="" />
						<div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/images/avatar/avatar_9.jpg" alt="" />
							</span>
							<span className="author-name">
								<a href="#">By Miller</a>
							</span>
						</div>
					</div>
					<div className="entry-header">
						<div className="entry-meta">
							<span className="date">
								<a href="#"  rel="bookmark" className="entry-date">
									13 January 2017
								</a>
							</span>
						</div>
						
						<h4 className="entry-title">
							<a href="#">Provide pure water for syrian poor people</a>
						</h4>
					</div>
					<span className="xs-separetor"></span>
					<div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div>
				</div>
			</div> */}
		</div>
        </section>
	
		{/* <ul className="pagination justify-content-center xs-pagination">
	<li className="page-item disabled">
		<a className="page-link" href="#" aria-label="Previous">
			<i className="fa fa-angle-left"></i>
		</a>
	</li>
	<li className="page-item"><a className="page-link active" href="#">1</a></li>
	<li className="page-item"><a className="page-link" href="#">2</a></li>
	<li className="page-item"><a className="page-link" href="#">3</a></li>
	<li className="page-item disabled"><a className="page-link" href="#">...</a></li>
	<li className="page-item"><a className="page-link" href="#">12</a></li>
	<li className="page-item">
		<a className="page-link" href="#" aria-label="Next">
			<i className="fa fa-angle-right"></i>
		</a>
	</li>
</ul>	 */}
	
	
    
            </main>
            
            <Footer/>
        </div>
    )
}
