import React from 'react'
import Navbar from '../NavBar/Navbar'
import Footer from '../Footer/Footer'

export default function Update1() {
    return (
        <div>
            <Navbar/>
     


            <main className="xs-main">
	
	<div className="xs-content-section-padding xs-blog-single">


	<div className="container">
		<div className="row">
			<div className="col-md-12 col-lg-8">
				 



                <article className="post format-standard hentry xs-blog-post-details">
					<div className="post-media post-image">
						<img src="assets/image/partnership1.jpeg" className="img-responsive" alt="" />
					</div>

					<div className="post-body xs-border xs-padding-40">
						<div className="entry-header">
							<div className="post-meta row">
								<div className="col-md-2 xs-padding-0">
				 					<span className="post-meta-date"><b>17</b> JANUARY</span>
				 				</div>
								
							</div>
			 				<h2 className="entry-title xs-post-entry-title">
				 				<a href="#"> Ruralnomics and Countryspices Partnership.</a>
				 			</h2>
						</div>
						
						<div className="entry-content">
							<p style={{textAlign:"justify", textJustify:"inter-word", fontSize: "20px"}}><strong><span className="color-orange">Ruralnomics Pvt Ltd, 
                                </span> </strong> a young e-commerce & supply startup, and Countryspices, an e-commence portal for spices produced in NE, announces strategic partnership to promote the spices industry of the region by taking the products to larger markets across India and eventually across the Globe.

A range of natural spices that are produced in the NE region of India have enormous health benefits, and the goal of the alliance is to bring this benefit to people across different states in India and abroad.

Both the partners will work together to maximise the reach of these products which will benefit the local farmers in the region to a great extent.</p>

							

			

                        </div>

					
					</div>
				</article>
				
				
				
	
				
			</div>
			<div className="col-md-12 col-lg-4">
			
				<div className="sidebar sidebar-right">



	
	<div className="widget widget_call_to_action">
		<a href="#" className="d-block">
			<img src="assets/images/side_add_baner.jpg" alt="" />
		</a>
	</div>
	


</div>			
			</div>
		</div>
	</div>
</div>


</main>
            <Footer/>
        </div>
    )
}
