import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyDr4DjZBuhFh-ZJXuGRL7N-yY-RcvDW4fk",
    authDomain: "rookmaindeploy.firebaseapp.com",
    databaseURL: "https://rookmaindeploy.firebaseio.com",
    projectId: "rookmaindeploy",
    storageBucket: "rookmaindeploy.appspot.com",
    messagingSenderId: "55883935469",
    appId: "1:55883935469:web:fdd63bbff8e18b405be6e4",
    measurementId: "G-CLHCEQNHJT"
};


firebase.initializeApp(firebaseConfig);
firebase.firestore();