import React from 'react'
import Navbar from '../NavBar/Navbar'


export default function Gallery() {
    return (
        <div>
            <Navbar/>
            {/* <section className="xs-banner-inner-section parallax-window" style={{backgroundImage:"url('assets/images/blog/blog_2.jpg')"}}>
	<div className="xs-black-overlay"></div>
	<div className="container">
		<div className="color-white xs-inner-banner-content">
			<h2>Blogs</h2>
			
		</div>
	</div>
</section> */}



<main className="xs-main">
	
	<section className="xs-content-section-padding">
	<div className="container">
			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/1.png" alt="" style={{height:"230px", width:"380px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>
	
			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/2.png" alt="" style={{height:"230px", width:"380px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>

			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/3.png" alt="" style={{height:"230px", width:"380px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>

			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/4.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/5.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>

			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/6.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>

			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/7.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>

			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/8.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>

			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/9.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/10.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/11.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/12.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/13.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/14.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/15.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/16.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/17.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/18.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/19.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/20.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/21.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/22.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/23.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/24.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/25.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/26.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/27.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/28.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/29.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/30.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/31.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/32.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/33.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/34.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/35.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/36.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/37.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/38.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/39.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/40.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/41.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>

			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/42.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/42.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/43.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/44.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>

			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/45.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>

			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/46.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>

			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/47.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/48.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/49.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/50.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/51.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/52.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/53.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>

			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/54.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/55.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/56.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/57.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/58.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/59.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>

			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/60.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/61.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/62.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/63.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/64.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/65.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/66.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/67.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/68.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/69.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/70.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/71.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/72.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/73.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/74.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>

			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/75.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/76.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/77.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/78.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>



			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/79.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/78.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>



			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/81.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>



			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/82.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>



			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/83.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>



			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/84.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>



			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/85.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>



			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/86.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>



			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/87.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>



			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/88.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>



			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/89.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>



			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/90.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>



			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/91.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/92.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/93.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/94.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>



			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/95.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/96.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


		
			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/98.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>

			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/99.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>


			<div className="col-lg-4 col-md-6">
				<div className="xs-box-shadow xs-single-journal  xs-mb-30">
					<div className="entry-thumbnail ">
						<img src="assets/image/RUNOFRESH/100.png" alt="" style={{height:"230px", width:"500px"}} />
						{/* <div className="post-author">
							<span className="xs-round-avatar">
								<img className="img-responsive" src="assets/image/preLOader/logo_1.png" alt="" />
							</span>
							<span className="author-name">
								<a href="/update1">By Ruralnomics Team</a>
							</span>
						</div> */}
					</div>
					<div className="entry-header">
						{/* <div className="entry-meta">
							<span className="date">
								<a href="/update1"  rel="bookmark" className="entry-date">
									17 JANUARY 2021
								</a>
							</span>
						</div> */}
						
						{/* <h4 className="entry-title">
							<a href="/update1">Ruralnomics & Countryspices Partnership.
</a>
						</h4> */}
					</div>
					<span className="xs-separetor"></span>
					{/* <div className="post-meta meta-style-color">
						<span className="comments-link">
							<i className="fa fa-comments-o"></i>
							<a href="#">300 Comments</a>
						</span>
						<span className="view-link">
							<i className="fa fa-eye"></i>
							<a href="#">1000 Views</a>
						</span>
					</div> */}
				</div>
			</div>

		
		</div>
        </section>
	

	
	
    
            </main>
            
         
        </div>
    )
}
